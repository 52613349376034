import React from 'react'
import { FieldValue } from '../components/carbon-entitysync'


function TextDisplay({field, question, required, type = 'text'}) {
    return (
        <div className="field bx--row">
            <div>
                <strong>{question.title}: </strong>
            </div>
            <div>
                <FieldValue field={field}></FieldValue>
            </div>
        </div>
    )
}

export default TextDisplay
