import { fetchAuthenticatedContent } from "@parallelpublicworks/entitysync";

export default async function  submitNValidateApplication(id, userContext) {
  const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null;
  
  let endpoint = `validate_application/${id}`;

  let response = await fetchAuthenticatedContent(userState.auth, userContext[1], endpoint, 'GET');
  
  return response;
}