import React, {useState, useContext, useEffect} from 'react';
import { Entity } from '@parallelpublicworks/entitysync'
import { navigate } from 'gatsby';
import {HeedContext} from '../gatsby-theme-carbon/components/Layout';
import setEntitySource from '../util/setEntitySource'
import TextDisplay from '../components/TextDisplay'
import AddressDisplay from '../components/AddressDisplay'
import CheckboxDisplay from '../components/CheckboxDisplay'
import LorDisplay from '../components/LorDisplay'
import { CheckmarkOutline32, Pending32 } from '@carbon/icons-react'
import getDemographicsExcluded from '../util/getDemographicsExcluded'
import { UserContext } from '@parallelpublicworks/entitysync'
import getLorsLink from '../util/getLorsLink';

const REACT_APP_ENTITYSYNC_BASE_URL = process.env.REACT_APP_ENTITYSYNC_BASE_URL;

const demographicsExcluded = getDemographicsExcluded();

function AnswersReview({ task, isReviewerView = false }) {
    
    const userContext = useContext(UserContext)
    const userState = userContext && typeof userContext[0] !== 'undefined' ? userContext[0] : null

    const questions = task.relationships.field_questions;
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [source, setSource] = useState(null)
    const heed = useContext(HeedContext);

    useEffect(() => {
      (async () => {
        let completed = heed?.answers?.completed_tasks?.includes(task.drupal_id);
        setTaskCompleted(completed);
        if(heed?.answers && heed?.answers.answers){
            let lorLinks = [];
            if(isReviewerView) {
              let lors = heed.answers.answers.relationships.field_letters_of_recommendation.data.filter(lor=>lor.id !== 'missing')
              lorLinks = await getLorsLink(lors, userState.auth, userContext)
            }
            setSource(setEntitySource({...heed.answers.answers, lorLinks}))
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    if(!isReviewerView && heed?.user?.id && !heed?.user?.is_eligible){
        navigate('/')
        return <></>
    }

    if(!source){
        return <></>
    }

    return (
        <div className={`task-review ${taskCompleted ? 'complete' : ''}`}>
            <h3>{taskCompleted ? <CheckmarkOutline32 /> : <Pending32 />} <span>{task.title}</span></h3>
            <Entity type="node--answers" source={source} componentId="task-entity">
                {questions.filter((question) => typeof question.internal !== 'undefined').map((question, index) => {

                    if(isReviewerView && demographicsExcluded.find(field => field === question.field_identifier )) return null

                    const is_required = question.field_is_required === true;
                    switch (question.internal?.type) {
                        case 'node__text_question':
                            return (
                              <TextDisplay
                                key={question.drupal_id}
                                type={question.field_type}
                                question={question}
                                field={`field_${question.field_identifier}`}
                                disabled={true}
                                required={is_required}
                              />
                            )
                          case 'node__checkbox_question':
                            return (
                              <CheckboxDisplay
                                key={question.drupal_id}
                                question={question}
                                field={`field_${question.field_identifier}`}
                                disabled={true}
                                required={is_required}
                              />
                            )
                          case 'node__address_question':
                            return (
                              <AddressDisplay
                                key={question.drupal_id}
                                question={question}
                                field={question.field_identifier}
                                disabled={true}
                                required={is_required}
                              />
                            )
                          case 'node__file_question':
                              
                            let field_file = source.attributes[`field_${question.field_identifier}`];

                            return (
                              <div key={question.drupal_id}>
                                {/* <FileDisplay
                                  key={question.drupal_id}
                                  question={question}
                                  field={`field_${question.field_identifier}`}
                                  disabled={true}
                                  required={is_required}
                                /> */}
                                { field_file?.attributes?.uri?.value &&
                                  <div className="field bx--row">
                                      <div>
                                          <strong>{question.title}: </strong>
                                      </div>
                                      <div>
                                          <a href={`${REACT_APP_ENTITYSYNC_BASE_URL}/${field_file.attributes.uri.value.replace('private://', 'system/files/')}`} target="_blank" rel="noreferrer">{question.title || "File" }</a>
                                      </div>
                                  </div>
                                }
                              </div>
                            )
                          case 'node__lor_question':
                              
                            let lor = source.attributes.field_letters_of_recommendation?.find(lor => lor.relationships.field_lor_question.data.id === question.drupal_id);

                            let { lorLinks } = source;
                            let file = lorLinks ? lorLinks.find(lorfile => lorfile.id === lor?.relationships?.field_letter?.data?.id) : {}

                            return (
                              <div key={question.drupal_id}>
                                <LorDisplay question={question} disabled={taskCompleted} heed={heed} />
                                { file?.attributes?.uri?.value &&
                                  <div className="field bx--row">
                                      <div>
                                          <strong>{question.title}: </strong>
                                      </div>
                                      <div>
                                          <a href={`${REACT_APP_ENTITYSYNC_BASE_URL}/${file.attributes.uri.value.replace('private://', 'system/files/')}`} target="_blank">Letter</a>
                                      </div>
                                  </div>
                                }
                              </div>
                            )
                          default:
                            return <></>
                            
                    }
                })}
            </Entity>
        </div>
    )
}

export default AnswersReview
