import { fetchAuthenticatedContent } from '@parallelpublicworks/entitysync'

export default function getLorsLink(lors, auth, userContext) {

    return new Promise( async (resolve, reject) => {

      try {
        let endpoint = `node/lor_request/`
        endpoint += `?filter[id-filter][condition][path]=id`
        endpoint += `&filter[id-filter][condition][operator]=IN`

        const inConditions = lors.map((lor, index) => (`&filter[id-filter][condition][value][${index}]=${lor.id}`))

        endpoint += inConditions.join('')
        endpoint += `&include=field_letter&fields[file--file]=uri,url`

        const resp = await fetchAuthenticatedContent(auth, userContext[1], endpoint, 'GET');
        
        resolve(resp?.included || []);
      } catch(e) {
        resolve([])
      }
    })
}