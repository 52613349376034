
export default function getDemographicsExcluded() {
    return [
        "gender_self_desc",
        "transgender",
        "racial_self_desc",
        "race",
        "natcitconfirm",
        "natdate",
        "natcertnum"
    ]
}