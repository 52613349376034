import React, {useContext} from 'react'
import { HeedContext } from '../gatsby-theme-carbon/components/Layout';

function CheckboxDisplay({field, question}) {
    const heed = useContext(HeedContext);
    const answer_relationships = heed?.answers?.answers?.relationships ? heed?.answers?.answers?.relationships : {}
    let answer_rel;
    if(typeof answer_relationships[field] !== 'undefined'){
      const answer_rel_data = Array.isArray(answer_relationships[field].data) ? answer_relationships[field].data : answer_relationships[field].data !== null ? [answer_relationships[field].data] : []
      answer_rel = answer_rel_data.map((a_rel) => a_rel.id);
    }
    if(!answer_rel){
      return <></>;
    }
    const answer_labels = heed.options.filter((op) => answer_rel.includes(op.id)).map((op) => op.title)
    if(!answer_labels.length) return <></>;
    return (
        <div className="field bx--row">
            <div>
                <strong>{question.title}: </strong>
            </div>
            <div>
                {answer_labels.join(',')}
            </div>
        </div>
    )
}

export default CheckboxDisplay
