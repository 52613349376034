import React, {useContext} from 'react'
import { HeedContext } from '../gatsby-theme-carbon/components/Layout';

export const states = {
    AL : 'Alabama',
    AK : 'Alaska',
    AS : 'American Samoa',
    AZ : 'Arizona',
    AR : 'Arkansas',
    AA : 'Armed Forces (AA)',
    AE : 'Armed Forces (AE)',
    AP : 'Armed Forces (AP)',
    CA : 'California',
    CO : 'Colorado',
    CT : 'Connecticut',
    DE : 'Delaware',
    DC : 'District of Columbia',
    FL : 'Florida',
    GA : 'Georgia',
    GU : 'Guam',
    HI : 'Hawaii',
    ID : 'Idaho',
    IL : 'Illinois',
    IN : 'Indiana',
    IA : 'Iowa',
    KS : 'Kansas',
    KY : 'Kentucky',
    LA : 'Louisiana',
    ME : 'Maine',
    MH : 'Marshall Islands',
    MD : 'Maryland',
    MA : 'Massachusetts',
    MI : 'Michigan',
    FM : 'Micronesia',
    MN : 'Minnesota',
    MS : 'Mississippi',
    MO : 'Missouri',
    MT : 'Montana',
    NE : 'Nebraska',
    NV : 'Nevada',
    NH : 'New Hampshire',
    NJ : 'New Jersey',
    NM : 'New Mexico',
    NY : 'New York',
    NC : 'North Carolina',
    ND : 'North Dakota',
    MP : 'Northern Marian Islands',
    OH : 'Ohio',
    OK : 'Oklahoma',
    OR : 'Oregon',
    PW : 'Palau',
    PA : 'Pennsylvania',
    PR : 'Puerto Rico',
    RI : 'Rhode Island',
    SC : 'South Carolina',
    SD : 'South Dakota',
    TN : 'Tennessee',
    TX : 'Texas',
    UT : 'Utah',
    VT : 'Vermont',
    VI : 'Virgin Islands',
    VA : 'Virginia',
    WA : 'Washington',
    WV : 'West Virginia',
    WI : 'Wisconsin',
    WY : 'Wyoming'
}


export const countries = {
    US : 'United States',
}

function AddressDisplay({field, question}) {
    const heed = useContext(HeedContext);
    const answer_attributes = heed?.answers?.answers?.attributes ? heed?.answers?.answers?.attributes : {}
    const defaults = [
        'field_address_line1',
        'field_address_line2',
        'field_city',
        'field_zip_code',
        'field_state',
        'field_country',
    ];

    const excludes = question.field_exclude_fields && question.field_exclude_fields.length > 0 ? question.field_exclude_fields : [];

    const subfields = defaults.filter((key_str) => !excludes.includes(key_str))

    const identifier = question.field_identifier;

    let address = []

    for (const key_str of subfields) {
        if(answer_attributes[`${key_str}_${identifier}`]){
            let lbl = answer_attributes[`${key_str}_${identifier}`];
            if(key_str === 'field_state' && states[answer_attributes[`${key_str}_${identifier}`]]){
                lbl = states[answer_attributes[`${key_str}_${identifier}`]]
            }else if(key_str === 'field_country' && countries[answer_attributes[`${key_str}_${identifier}`]]){
                lbl = countries[answer_attributes[`${key_str}_${identifier}`]]
            }
            address.push(lbl);
        }
    }

    return (
        <div className="field bx--row">
            <div>
                <strong>{question.title}: </strong>
            </div>
            <div>
                {address.join(', ')}
            </div>
        </div>
    )

}

export default AddressDisplay
